<template>
  <div class="app-container">
    <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="群发名称" prop="orderNo">
        <el-input v-model="queryParams.orderNo" placeholder="请输入群发名称" clearable size="mini"/>
      </el-form-item>


      <el-form-item label="状态" prop="payAccount">
        <el-select v-model="queryParams.payAccount" size="mini" :clearable="true">
          <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"
                     :label="item.dictLabel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建日期" prop="orderNo">
        <el-input v-model="queryParams.orderNo" placeholder="请输入群发名称" clearable size="mini"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <template>
      <el-button
          type="primary"
          v-permission="'sys:receive:add'"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
      >新增
      </el-button>
    </template>
    <el-table max-height="600"  v-loading="loading" :data="repairList ">
      <el-table-column type="名称" width="55" align="center"/>
      <el-table-column label="内容" align="center" prop="orderNo" style="width: 100% "/>
      <el-table-column label="发送总人数" align="center" prop="opporId" style="width: 100% "/>
      <el-table-column label="状态" align="center" prop="goodsName" style="width: 100% "/>
      <el-table-column label="创建人" align="center" prop="price" style="width: 100% ">
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="payTime" type="date" style="width: 100% "/>
      <el-table-column label="操作" align="center" prop="贴标签" style="width: 100% ">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" v-for="item in orderStatusList" :key="item.value" :type="item.type"
                  effect="dark"
                  v-if="scope.row.status === item.value">
            {{ item.label }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        :v-show="queryParams.total>10"
        :total="queryParams.total"
        :page.sync="queryParams.current"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <OrderRepairDetail @getList="getList" :dialogValue.sync="repairShow" :repair-id="repairId" v-if="repairShow"
                       :repairStatus.sync="repairStatus"></OrderRepairDetail>
  </div>
</template>

<script>
import * as api from "@/utils/api";
import * as repairApi from "@/api/crm/orderRepair"
import OrderRepairDetail from "@/components/order/OrderRepairDetail.vue"
import {getUserId} from "@/utils/auth";
import * as  accountApi from "@/api/system/account";
import * as dectApi from '@/api/system/dictionary'

export default {
  name: "OrderRepair",
  components: {
    OrderRepairDetail
  },
  props: {},
  data() {
    return {
      withdraw: false,
      repairStatus: 0,
      repairId: 0,
      repairShow: false,
      payTimeArr: [],
      queryParams: {
        orderNo: null,
        goodsName: null,
        findType: 0,
        total: 0,
        current: 1,
        size: 10,
        status: null,
        startDateTime: null,
        endDateTime: null,
      },
      repairList: [],
      loading: false,
      adviserList: [],
      accountList: [],
      possessList: [],
      searchRules: {
        opporId: [
          {min: 1, max: 9, message: '请输入正确的机会ID', trigger: 'blur'}
        ]
      },
      orderChannelList: [],
      orderStatusList: [
        {value: 0, label: '未提交/驳回', type: 'danger'},
        {value: 1, label: '一审审批中', type: 'warning'},
        {value: 2, label: '财务核对中', type: 'warning'},
        {value: 9, label: '完成(未录入)', type: 'success'},
        {value: 10, label: '完成(已录入)', type: 'success'},
      ],
      collectionWayList: [],
      findTypeList: [
        {value: 0, label: "我的录单"},
        {value: 1, label: "审批录单"},
        {value: 2, label: "审核记录"},
        {value: 3, label: "全部"},
      ],
      payAccountArr: [],
    };
  },
  created() {
    this.init();
    this.getAdviserList()
    this.getPossessList()
    this.getAccountList()
    dectApi.list({dictParent: 14}).then(resp => {
      if (resp.success) {
        this.payAccountArr = resp.data
      } else this.$message.error(resp.msg)
    })
  },
  computed: {
    userId: function () {
      return getUserId()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 初始化数据
    init() {
      dectApi.list({dictParent: 14}).then(resp => {
        if (resp.success) {
          this.payAccountArr = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 15}).then(resp => {
        if (resp.success) {
          this.collectionWayList = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 16}).then(resp => {
        if (resp.success) {
          this.orderChannelList = resp.data
        } else this.$message.error(resp.msg)
      })
    },
    repairInfo(repairId) {
      this.repairId = repairId
      this.repairStatus = 9;
      this.repairShow = true
    },
    /**
     * 修改录单
     */
    updateRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 1;
      this.repairShow = true
    },
    /**
     * 审核
     */
    examineRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 2;
      this.repairShow = true
    },
    /**
     * 提交
     */
    submitRepair(row) {
      repairApi.submitRepairId(row).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 撤回
     */
    backRepair(repairId) {
      repairApi.backRepair(repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 新增
    showDetail() {
      this.repairId = 0
      this.repairStatus = 0
      this.repairShow = true
    },
    //金额格式化
    numberformat(number) {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
          prec = !isFinite(+2) ? 0 : Math.abs(2),
          s = '',
          toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.floor(n * k) / k;
          };
      s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join('.');
    },
    /**
     * 获取唯一值配置
     */
    getPossessList() {
      api.possessList().then(res => {
        this.possessList = res.data
      })
    },
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    getAccountList: function () {
      accountApi.list().then(res => {
        console.log(res)
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true
      if (this.payTimeArr && this.payTimeArr[0]) {
        this.queryParams.startDateTime = this.payTimeArr[0]
        this.queryParams.endDateTime = this.payTimeArr[1]
      } else {
        this.queryParams.startDateTime = null
        this.queryParams.endDateTime = null
      }
      this.queryParams['submitId'] = this.submitId
      repairApi.listPage(this.queryParams).then(resp => {
        if (resp.success) {
          this.repairList = resp.data.records
          this.queryParams.total = resp.data.total
          this.queryParams.size = resp.data.size
          this.queryParams.current = resp.data.current
          this.loading = false
        } else {
          this.$message.error(resp.msg)
          this.queryParams.findType = 0
          this.getList()
          this.loading = false
        }

      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },

    handleAdd() {
      this.$router.push({
        path: '/SphereSendingAdd'
      })
    },

    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.payTimeArr = [];
      this.queryParams.startDateTime = null;
      this.queryParams.endDateTime = null;
      this.handleQuery();
    },
    deleteOrderRepair(orderRepairId) {
      repairApi.deleteOrderRepair(orderRepairId).then(res => {
        if (res.success) {
          this.$message.success(`删除成功!`);
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
.small-padding button {
  margin: 3px;
}
</style>
